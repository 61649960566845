<template>
	<el-dialog title="选择观察" :visible.sync="dialogVisible">
		<div class="a-flex-rfsc">
			<le-input-icon-search placeholder="请输入观察名称" v-model="pageParam.params.name"></le-input-icon-search>
		</div>
		<div class=" a-mt-24">
			<le-pagview @setData="setTableData" :pageParam="pageParam">
				<el-table :data="tableData" height="425">
					<el-table-column property="name" label="观察名称"></el-table-column>
					<el-table-column label="操作" width="80">
						<template slot-scope="{ row }">
							<el-button type="info" plain @click="handlerChoose(row)">选择</el-button>
						</template>
					</el-table-column>
				</el-table>
			</le-pagview>
		</div>

	</el-dialog>
</template>
<script>
import util from '../../../utils/util.js'
/**
 * 现勘选择
 */
export default {
	props: {
		visible: {
			required: true,
			type: Boolean,
			default: false,
		},
		observeId: {
			required: false,
		},
		observeName: {
			required: false,
		},
	},
	data() {
		return {
			util: util,
			dialogVisible: false,
			pageParam: {
				url: this.$Config.apiUrl.getObserveList,
				method: "post",
				params: {
					name: '',
				},
			},
			tableData: []
		};
	},
	watch: {
		visible: {
			handler(val) {
				this.dialogVisible = val;
			},
		},
		dialogVisible: {
			handler(val, oldval) {
				if (val != oldval) {
					this.$emit("update:visible", val)
				}
			}
		},
	},
	mounted() {

	},
	methods: {
		handlerChoose(item) {
			this.dialogVisible = false
			this.$emit("update:observeId", item.id)
			this.$emit("update:observeName", item.name)
            this.$emit('handleChoose')
		},
		setTableData(data) {
			this.tableData = data.data;
		},

	},
};
</script>
