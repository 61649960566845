<template>
	<div class="app-body">
		<bread-crumb></bread-crumb>
		<el-card class="el-main">
            <div class="a-fs-16 a-c-333 a-fw-b">
				<span>项目信息</span>
			</div>
			<div class="a-flex-cfsfs a-mt-10">
				<le-input-slot label="项目收益" notnull>
					<template v-if="!request.incomeDistriId">
						<el-button class="s-btn-add-blue-dot" icon="el-icon-plus" @click="handlerChooseIncome">选择</el-button>
					</template>
					<template v-else>
						<div class="a-flex-rfsfs s-large-input">
							<el-input readonly v-model="request.incomeDistriName"></el-input>
							<el-button class="s-btn-add-blue-dot a-ml-15" icon="el-icon-refresh-right" @click="incomeVisiable = true">重选</el-button>
						</div>
					</template>
				</le-input-slot>
			</div>
			<div class="a-fs-16 a-c-333 a-fw-b">
				<span>申报信息</span>
			</div>
			<div class="a-flex-cfsfs a-mt-10">
				<le-switch-btn label="申报类型" v-model="request.applyType" :options="applyTypeOptions" @input="handleChangeApplyType" notnull :disabled='applyTypeDisabled'></le-switch-btn>
                <le-select-local-search large label="所属商户" v-model="request.companyId" :options="optionsCompanyByIncome" :readonly="request.applyType=='0'?true:false" />
                <le-select-local-search large label="运营方" v-model="request.operationCompanyId" :options="optionsCompanyByIncome" />
				<!-- <le-company-under-select label="所属商户" v-model="request.companyId" placeholder="请选择（可搜索）" notnull
					large :readonly="request.applyType=='0'"/>
				<le-company-all-select label="运营方" v-model="request.operationCompanyId" placeholder="请选择（可搜索）" notnull
					large /> -->
				<le-input-slot label="现勘记录">
					<template v-if="!request.surveyAreaId">
						<el-button class="s-btn-add-blue-dot" icon="el-icon-plus" @click="surveyVisiable = true">选择
						</el-button>
					</template>
					<template v-else>
						<div class="a-flex-rfsfs s-large-input">
							<el-input readonly v-model="request.surveyStationName"></el-input>
							<el-button class="s-btn-add-blue-dot a-ml-15" icon="el-icon-refresh-right"
								@click="surveyVisiable = true">重选
							</el-button>
						</div>
					</template>
				</le-input-slot>
                <le-input-slot label="站点观察" v-if="company.companyType == 1">
					<template v-if="!request.observeId">
						<el-button class="s-btn-add-blue-dot" icon="el-icon-plus" @click="observeVisible = true">选择
						</el-button>
					</template>
					<template v-else>
						<div class="a-flex-rfsfs s-large-input">
							<el-input readonly v-model="request.observeName"></el-input>
							<el-button class="s-btn-add-blue-dot a-ml-15" icon="el-icon-refresh-right"
								@click="observeVisible = true">重选
							</el-button>
						</div>
					</template>
				</le-input-slot>
			</div>

			<div class="a-fs-16 a-c-333 a-mt-30 a-fw-b">
				<span>站点信息</span>
			</div>
			<div class="a-flex-cfsfs a-mt-10">
				<le-input label="站点名称" v-model="request.name" notnull large />
				<le-input-address-point label="详细地址" :province.sync="request.provinceCode" :city.sync="request.cityCode"
					:area.sync="request.districtCode" :address.sync="request.address" :lat.sync="request.lat"
					:lng.sync="request.lng" large></le-input-address-point>
				<le-input-num label="规划容量(台)" v-model="request.capacity" placeholder="请输入" notnull large></le-input-num>
                <le-select-remote-search label="站点标签" v-model="request.tagIdList" multiple :options="optionsStationlabel" placeholder="请选择标签(可搜索)" />
                <le-switch-btn label="站点类型" v-model="request.stationType" :options="stationTypeDic" notnull></le-switch-btn>
                <le-switch-btn label="烟雾报警自动断电" v-model="request.smokeCharge" :options="smokeChargeDic" notnull></le-switch-btn>
                <!-- <le-switch-btn label="站点类型" :disabled="stationId?true:false" v-model="request.stationType" :options="stationTypeDic" notnull></le-switch-btn> -->
                <le-upload-file-img class="updateImg" ref="backImg" label="站点图片" :limit="1" v-model="request.backImg"></le-upload-file-img>
                <div class="a-flex-rfsc a-mt-12">
                    <span class="a-fs-14 a-c-666">站点附加条件</span>
                    <div class="a-ml-24 a-c-666">
                        <el-checkbox-group v-model="request.attchInfo">
                            <el-checkbox v-for="(item,index) in attchInfoDic" :key="index" :label="item.value">{{ item.text }}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="a-flex-rfsfs a-mt-24">
                    <span class="a-fs-14 a-c-666">禁用支付方式</span>
                    <el-button icon="el-icon-plus" type="primary" class="s-btn-add a-ml-24" @click="addBanPay">添加</el-button>
                </div>
                <el-table
                    :data="stationBanPayType"
                    max-height="500px"
                    style="width: 800px;margin-left: 108px;margin-top: 12px;">
                    <el-table-column
                        label="支付方式"
                        min-width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="线上/实体卡"
                        min-width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.online?'线上':'实体卡' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="订单类型"
                        min-width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderType | initDic(orderTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="70">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="删除" placement="top">
                                <img src="../../assets/icon/option-refuse.png" class="a-wh-16 a-ml-12" @click="removePayChannel(scope.$index)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
			</div>

			

			<div class="a-fs-16 a-c-333 a-mt-30 a-fw-b">
				<span>定价模板</span>
			</div>
			<div class="a-flex-cfsfs a-mt-10">
				<le-input-slot label="站点定价">
					<template v-if="!request.planId">
						<el-button class="s-btn-add-blue-dot" icon="el-icon-plus" @click="priceVisiable = true">选择
						</el-button>
					</template>
					<template v-else>
						<div class="a-flex-rfsfs s-large-input">
							<el-input readonly v-model="request.planName"></el-input>
							<el-button class="s-btn-add-blue-dot a-ml-15" icon="el-icon-refresh-right"
								@click="priceVisiable = true">重选
							</el-button>
						</div>
					</template>
				</le-input-slot>
			</div>
			<div style="padding: 50px 0;"></div>
			<div class="a-line-t-e0 footerBox">
				<el-button type="primary" class="a-ml-24 a-mt-15" @click="handlerSumbit">提交申报</el-button>
				<el-button type="infor" class="a-ml-24 a-mt-15"  @click="handlerCancel">取消</el-button>
			</div>
		</el-card>


		<le-survey-choose 
            :visible.sync="surveyVisiable" 
            :areaId.sync="request.surveyAreaId"
			:areaName.sync="request.surveyAreaName" 
            :stationId.sync="request.surveyStationId"
			:stationName.sync="request.surveyStationName"></le-survey-choose>
		<le-project-choose 
            :visible.sync="incomeVisiable" 
            :incomeId.sync="request.incomeDistriId" 
            :incomeName.sync="request.incomeDistriName" 
            :companyId="request.companyId" 
            @change='chooseProjectSuccess'
            :status="4"></le-project-choose>
		<le-price-choose 
            :visible.sync="priceVisiable" 
            :priceId.sync="request.planId" 
            :priceName.sync="request.planName"></le-price-choose>
		<le-address-choose 
            :visible="true" 
            :priceId.sync="request.planId" 
            :priceName.sync="request.planName"></le-address-choose>

        <le-observe-choose 
            :visible.sync="observeVisible"
            :observeId.sync="request.observeId"
            :observeName.sync="request.observeName"></le-observe-choose>
        <add-ban-pay-channel ref="addBanPayChannel" @change="banPayChannelChange"></add-ban-pay-channel>
	</div>

</template>

<script>
	import util from "../../utils/util.js";
	import {
		mapState,
		mapActions
	} from 'vuex';
    import leObserveChoose from '../components/choose/le-observe-choose.vue';
    import AddBanPayChannel from './child/addBanPayChannel.vue';
	export default {
        components: { leObserveChoose, AddBanPayChannel },

		data() {
			return {
				util: util,
                stationId: '',
				request: {
					applyType: '', //申请类型 0:自营 1:代建
					name: '',
					companyId: null, //所属公司ID
					operationCompanyId: '', //运营方公司ID,
					provinceCode: '',
					cityCode: '',
					districtCode: '',
					address: '',
					lng: 0,
					lat: 0,
					capacity: '',
					surveyAreaId: '',
					surveyAreaName: '',
					surveyStationId: '',
					surveyStationName: '',
					incomeDistriId: '',
					incomeDistriName: '',
					planId: '',
					planName: '',
                    stationType: '',//站点类型
                    backImg: '',// 站点图片
                    attchInfo: [],
                    observeId: '',
                    observeName: '',
                    tagIdList: [],//标签管理
                    smokeCharge: 0,//烟雾报警是否自动断电
				},

				applyTypeOptions: [{
						value: '0',
						label: '自营'
					},
					{
						value: '1',
						label: '代建'
					}
				],
                optionsStationlabel: {
                    url: this.$Config.apiUrl.getStationTagList, //getIncomeStatInfoList
                    method: "post",
                    params: {
                        searchKey: "",
                    },
                    showLoading: false,
                    labelKey: "name",
                    valueKey: "id",
                    searchKey: "name",
                },
                stationTypeDic: [],
				surveyVisiable: false, //现勘选择弹窗是否显示
				incomeVisiable: false, //项目选择弹窗是否显示
				priceVisiable: false, //站点定价选择弹窗是否显示
                observeVisible: false,
                attchInfoDic: [],
                stationBanPayType: [],
                payChannelDic: [],
                orderTypeDic: [],
                smokeChargeDic: [{
                    label: '是',
                    value: 1
                },{
                    label: '否',
                    value: 0
                }],
                optionsCompanyByIncome: [],//收益方案相关联的商户
                applyTypeDisabled: false
			}
		},
		computed: {
			...mapState({
				company: state => state.company.company,
			})
		},

		mounted() {
			if (this.$route.query.stationId) {
				this.stationId = this.$route.query.stationId
				this.getStationDetail();
			}
            this.$getDic('stationType','select').then(res=>{ 
                this.stationTypeDic = res; 
                this.request.stationType = this.stationTypeDic[0].value
            })
            this.$getDic('stationAttchInfo').then(res=>{ 
                this.attchInfoDic = res; 
                if(!this.stationId){
                    this.request.attchInfo = this.attchInfoDic.map(item=>{
                        return item.value
                    })
                }
                
            })
            this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
            this.$getDic('orderType').then(res=>{ this.orderTypeDic = res; })
		},
		methods: {
			/**
			 * 获取站点详细信息
			 */
			getStationDetail() {
				this.$Axios._get({
					url: this.$Config.apiUrl.getStationDetail,
					params: {
						stationId: this.stationId
					}
				}).then(({
					data
				}) => {
					Object.assign(this.request, {
						...data.station,
						planId: data.station.chargingPlanId,
						planName: data.station.charingPlanName,
						incomeDistriName: data.incomeDistri.name,
                        attchInfo: JSON.parse(data.station.attchInfo),
                        backImg: data.station.backImg?JSON.parse(data.station.backImg)[0]:'',
                        tagIdList: data.station.tagList.map(item=>{ return item.id })
					})
                    this.optionsCompanyByIncome = data.incomeDistri.targetList.map(item=>{
                        return {
                            ...item,
                            label: item.targetCompanyName,
                            value: item.targetCompanyId,
                        }
                    })

                    this.applyTypeDisabled = true

                    this.getBanPayChannel()
                    setTimeout(()=>{
                        this.request.incomeDistriId = data.incomeDistri.id
                    },200)
				})
			},
			handlerSumbit() {
				if(this.request.capacity<0){
					this.$message.error("请输入正确的规划容量");
					return;
				}
				if (this.stationId) {
					this.$Axios._post({
						url: this.$Config.apiUrl.editStation,
						params: {
							...this.request,
                            attchInfo: JSON.stringify(this.request.attchInfo),
                            backImg: JSON.stringify([this.request.backImg]),
							id: this.stationId,
                            banPayChannelValidList: this.stationBanPayType
						}
					}).then((res) => {
						this.$message.success('修改站点成功')
						this.$router.back()
                        this.$store.dispatch("delView", this.$route);
					})
				} else {
					this.$Axios._post({
						url: this.$Config.apiUrl.createStation,
						params: {
                            ...this.request,
                            attchInfo: JSON.stringify(this.request.attchInfo),
                            backImg: JSON.stringify([this.request.backImg]),
                            banPayChannelValidList: this.stationBanPayType
                        }
					}).then((res) => {

						this.$message.success('创建站点成功')
						this.$router.back()
                        this.$store.dispatch("delView", this.$route);
					})
				}

			},
			handlerCancel() {
				this.$router.back()
                this.$store.dispatch("delView", this.$route);
			},
			handlerChooseIncome(){
                this.incomeVisiable = true
				// if(this.request.companyId){
				// 	this.incomeVisiable = true
				// }else{
				// 	this.$message.warning("请先选择所属商户")
				// }
			},
            // 选择收益方案
            chooseProjectSuccess (datas) {
                if(datas && datas.targetList) {
                    this.applyTypeDisabled = false
                    this.request.applyType = ''
                    this.request.companyId = ''
                    this.request.operationCompanyId = ''
                    this.optionsCompanyByIncome = datas.targetList.map(item=>{
                        return {
                            ...item,
                            label: item.targetCompanyName,
                            value: item.targetCompanyId,
                        }
                    })
                    let hasOwnerCompany = this.optionsCompanyByIncome.findIndex(res=>{
                        return res.targetCompanyId == this.company.id
                    })
                    // 如果当前选择的收益方案关联的商户中不包含当前登录商户，则不可选择自营
                    if(hasOwnerCompany == -1) {
                        this.applyTypeOptions = [
                            {
                                value: '1',
                                label: '代建'
                            }
                        ]
                        this.request.applyType = "1"
                    }else {
                        this.applyTypeOptions = [{
                                value: '0',
                                label: '自营'
                            },
                            {
                                value: '1',
                                label: '代建'
                            }
                        ]
                    }
                }
            },
            handleChangeApplyType (val) {
                if(!this.request.incomeDistriId) {
                    this.$message.error('请先选择收益方案')
                    return
                }
                if(val=='0'){
                    this.request.companyId=this.company.id
                }
            },
            getBanPayChannel () {
                this.$Axios._get({
					url: this.$Config.apiUrl.getBanPayChannel,
					params: {
						stationId: this.stationId
					}
				}).then(({
					data
				}) => {
                    this.stationBanPayType = data.map(item=>{
                        return {
                            ...item,
                            payChannel: item.banPayChannel
                        }
                    })
				})
            },
            addBanPay () {
                this.$refs['addBanPayChannel'].dialogVisible = true
            },
            removePayChannel (index) {
                this.stationBanPayType.splice(index,1);
            },
            banPayChannelChange (datas) {
                this.stationBanPayType = this.stationBanPayType.concat(datas)
                this.stationBanPayType = this.uniqueArr(this.stationBanPayType)
            },
            //数组去重
            uniqueArr (arr) {
                try{
                    for(var i=0; i<arr.length; i++){
                        for(var j=i+1; j<arr.length; j++){
                            if(arr[i].online==arr[j].online && arr[i].orderType==arr[j].orderType && arr[i].payChannel==arr[j].payChannel){         //第一个等同于第二个，splice方法删除第二个
                                arr.splice(j,1);
                                j--;
                            }
                        }
                    }
                    return arr;
                }catch(err){
                }
            },
		}
	}
</script>

<style scoped lang="scss">
    .updateImg{
        /deep/ &>div:nth-child(2){
            margin-left: 24px;
        }
    }
</style>
