<template>
    <div>
        <el-dialog
            title="禁用支付方式"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="600px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="140px">
                <el-form-item label="线上/实体卡" prop="online">
                    <el-radio-group v-model="form.online">
                        <el-radio :label="1">线上</el-radio>
                        <el-radio :label="0">实体卡</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="订单类型" prop="orderType">
                    <el-select v-model="form.orderType" placeholder="请选择" clearable>
                        <el-option v-for="item in orderTypeDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="支付方式" prop="payChannel">
                    <el-select v-model="form.payChannel" multiple collapse-tags placeholder="请选择" clearable>
                        <el-option v-for="item in payChannelDic"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    online: '',
                    orderType: '',
                    payChannel: []
                },
                id: '',
                rules:{

                },
                payChannelDic: [],
                orderTypeDic: []
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form'] && !val){
                    this.$refs['form'].resetFields()
                }
            },
        },
        created () {
            this.$getDic('payChannel','select').then(res=>{ this.payChannelDic = res; })
            this.$getDic('orderType','select').then(res=>{ this.orderTypeDic = res; })
        },  
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.dialogVisible = false
                        let datas = []
                        this.form.payChannel.map(item=>{
                            datas.push({
                                online: this.form.online,
                                orderType: this.form.orderType,
                                payChannel: item
                            })
                        })
                        this.$emit('change',datas)
                    }
                })
            }
        }
    }
</script>